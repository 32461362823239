import "./style.css";
import ImagesBrick from "@blocks/ImagesBrick";
import "@blocks/NumbersGrid";

const LIBRO_IMAGES = [
  { src: "https://i.imgur.com/9XUQ4Eu.jpg", alt: "" },
  { src: "https://i.imgur.com/fApt5RG.jpg", alt: "" },
  { src: "https://i.imgur.com/SUQej51.jpg", alt: "" },
  { src: "https://i.imgur.com/yt0J7ZR.jpg", alt: "" },
];

const EVENT_IMAGES = [
  { src: "https://i.imgur.com/P1OPeeg.jpg", alt: "" },
  { src: "https://i.imgur.com/iEJHA5c.jpg", alt: "" },
  { src: "https://i.imgur.com/AHjH5WN.jpg", alt: "" },
  { src: "https://i.imgur.com/TnLhsFm.jpg", alt: "" },
];

export default class Projects {
  static selector = ".projects";

  constructor(block) {
    this.block = block;
    this.libroImagesBrickNode = block.querySelector(
      ".projects__cultura-images"
    );
    this.libroImagesBrick = new ImagesBrick(
      this.libroImagesBrickNode,
      LIBRO_IMAGES
    );
    this.eventImagesBrickNode = block.querySelector(".projects__event-images");
    this.eventImagesBrick = new ImagesBrick(
      this.eventImagesBrickNode,
      EVENT_IMAGES
    );
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.libroImagesBrick.onReady();
      await this.eventImagesBrick.onReady();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();
  };

  onResize = () => {};
}
