import "./style.css";
import { clamp, map } from "@utils/math.js";

const MSDF_ATLASES_TO_LOAD = [];

export default class Loader {
  static selector = ".loader";

  constructor(block) {
    this.block = block;

    this.resourcesToLoad = 0;
    this.resourcesLoaded = 0;
    this.reasourcesProgress0 = 0;
    this.reasourcesProgress = 0;

    this.preloadedResources = {};
  }

  loadFontFaces = async () => {
    await document.fonts.ready;
  };

  loadImages = () => {
    this.imagesToLoad = document.querySelectorAll("img[src]:not([src=''])");
    this.resourcesToLoad += this.imagesToLoad.length;

    if (this.resourcesToLoad === 0) {
      this.reasourcesProgress = 1;
      return;
    }

    const loadImagePromises = [];

    this.imagesToLoad.forEach((imageToLoad) => {
      loadImagePromises.push(
        new Promise((resolve) => {
          const onImageLoaded = () => {
            this.resourcesLoaded += 1;

            this.reasourcesProgress =
              this.resourcesLoaded / this.resourcesToLoad;

            this.preloadedResources[imageToLoad.src] = imageToLoad;

            // console.log(
            //   "%c Image ",
            //   "background: yellow; color: black",
            //   "loaded"
            // );

            resolve();
          };

          if (imageToLoad.complete) {
            onImageLoaded();
          } else {
            imageToLoad.addEventListener("load", onImageLoaded);
          }
        })
      );
    });

    return Promise.all(loadImagePromises);
  };

  load = () => {
    const loadImagesPromise = this.loadImages();

    return Promise.all([loadImagesPromise]);
  };

  hide = () => {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        this.block.classList.add("loader--hidden");
        document.body.classList.add("loaded");
        await this.handleTransitionEnd();

        resolve();
      }, 480);
    });
  };

  handleTransitionEnd = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.block.style.pointerEvents = "none";
        this.block.classList.add("loader--loaded");

        resolve();
      }, 1100);
    });
  };
}
