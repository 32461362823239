import { getRaf, getScroll } from "@app";
import { clamp } from "@utils/math";

import "./style.css";

export default class Carousel {
  static name = "Carousel";

  constructor(block) {
    this.block = block;
    this.itemNodes = this.block.querySelectorAll(".carousel__items__item");
    this.itemsNode = this.block.querySelector(".carousel__items");

    this.index = 0;
    this.animations = {
      itemsNode: {
        node: this.itemsNode,
        styles: {
          translateX: {
            dampingFactor: 0.09,
            deltaX: 0,
            deltaX0: 0,
            fromValue: 0,
            setValue: () => {
              this.animations.itemsNode.styles.translateX.deltaX0 =
                this.animations.itemsNode.styles.translateX.deltaX0 +
                (this.animations.itemsNode.styles.translateX.deltaX -
                  this.animations.itemsNode.styles.translateX.deltaX0) *
                  this.animations.itemsNode.styles.translateX.dampingFactor;

              return this.animations.itemsNode.styles.translateX.deltaX0;
            },
          },
        },
      },
    };

    this.onIndexChangeCallbacks = [];
  }

  setItemsRect = () => {
    if (!this.itemNodes || this.itemNodes.length < 2) {
      return null;
    }

    this.itemNodes.forEach((node) => {
      node.rect = node.getBoundingClientRect();
      node.left = node.offsetLeft;
    });

    this.itemsGutter = this.getItemsGutter();
  };

  getItemsGutter = () => {
    if (!this.itemNodes || this.itemNodes.length < 2) {
      return null;
    }

    return (
      this.itemNodes[1].rect.left -
      (this.itemNodes[0].rect.width + this.itemNodes[0].rect.left)
    );
  };

  setItemsNodeMargin = () => {
    if (!this.itemsNode) {
      return null;
    }

    this.itemsNodeMargin = this.itemNodes[0].left;
  };

  onTouchStart = (e) => {
    this.block.style.cursor = "grabbing";

    this.animations.itemsNode.styles.translateX.x0 =
      (e.touches && e.touches[0] && e.touches[0].clientX) || e.clientX;
  };

  onTouchMove = (e) => {
    if (!this.animations.itemsNode.styles.translateX.x0) {
      return;
    }

    this.animations.itemsNode.styles.translateX.x =
      (e.touches && e.touches[0] && e.touches[0].clientX) || e.clientX;

    this.animations.itemsNode.styles.translateX.dX =
      this.animations.itemsNode.styles.translateX.x -
      this.animations.itemsNode.styles.translateX.x0;

    this.animations.itemsNode.styles.translateX.deltaX =
      this.animations.itemsNode.styles.translateX.x -
      this.animations.itemsNode.styles.translateX.x0 +
      this.animations.itemsNode.styles.translateX.fromValue;

    if (Math.abs(this.animations.itemsNode.styles.translateX.dX) > 50) {
      this.scroll.stop();
    }
  };

  onTouchEnd = (e) => {
    this.block.style.cursor = null;

    if (
      !this.animations.itemsNode.styles.translateX.x0 ||
      !this.animations.itemsNode.styles.translateX.x
    ) {
      this.animations.itemsNode.styles.translateX.x = null;
      this.animations.itemsNode.styles.translateX.x0 = null;

      return;
    }

    this.updateIndex();

    this.reset();
  };

  onMouseOut = () => {
    this.block.style.cursor = null;

    if (!this.animations.itemsNode.styles.translateX.x0) {
      return;
    }

    this.updateIndex();

    this.reset();
  };

  reset = () => {
    this.animations.itemsNode.styles.translateX.x =
      this.animations.itemsNode.styles.translateX.x0 = null;

    this.scroll.start();
  };

  updateIndex = () => {
    const { width } = this.bounds;

    if (Math.abs(this.animations.itemsNode.styles.translateX.dX) > width / 5) {
      const value = Math.ceil(
        Math.abs(this.animations.itemsNode.styles.translateX.dX) / (width / 3)
      );

      this.index =
        this.animations.itemsNode.styles.translateX.dX < 0
          ? this.index + value
          : this.index - value;

      //const itemsInView = Math.trunc(this.block.offsetWidth / this.itemWidth);
    }

    this.index = clamp(this.index, 0, this.itemNodes.length - 1);

    const lastItemRight =
      this.bounds.width -
      this.itemNodes[this.itemNodes.length - 1].rect.width -
      this.itemsNodeMargin * 2;

    const value = clamp(
      this.itemNodes[this.index].left,
      0,
      this.itemNodes[this.itemNodes.length - 1].left - lastItemRight
    );
    this.animations.itemsNode.styles.translateX.deltaX =
      this.animations.itemsNode.styles.translateX.fromValue =
        (value - this.itemsNodeMargin) * -1;

    this.animations.itemsNode.styles.translateX.dX = 0;

    this.onIndexChangeCallbacks.forEach((callback) => {
      callback(this.index);
    });
  };

  setIndex = (newIndex) => {
    this.index = newIndex;
    this.updateIndex();
  };

  increaseIndex = () => {
    this.setIndex(this.index + 1);
  };

  decreaseIndex = () => {
    this.setIndex(this.index - 1);
  };

  registerOnIndexChange = (callback) => {
    this.onIndexChangeCallbacks.push(callback);
  };

  render = () => {
    for (const key in this.animations.itemsNode.styles) {
      this.animations.itemsNode.styles[key].current =
        this.animations.itemsNode.styles[key].setValue();
    }

    this.layout();
  };

  layout = () => {
    this.itemsNode.style.transform = `translate3d(${this.animations.itemsNode.styles.translateX.current}px, 0, 0)`;
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.block.addEventListener("touchstart", this.onTouchStart, {
        passive: true,
      });
      this.block.addEventListener("touchmove", this.onTouchMove, {
        passive: true,
      });
      this.block.addEventListener("touchend", this.onTouchEnd, {
        passive: true,
      });
      this.block.addEventListener("mousedown", this.onTouchStart);
      this.block.addEventListener("mousemove", this.onTouchMove);
      this.block.addEventListener("mouseup", this.onTouchEnd);
      this.block.addEventListener("mouseleave", this.onMouseOut);

      this.scroll = getScroll();

      this.onResize();

      resolve();
    });
  };

  onResize = () => {
    this.bounds = this.block.getBoundingClientRect();
    this.setItemsRect();
    this.setItemsNodeMargin();
    this.updateIndex();

    if (!this.raf) {
      this.raf = getRaf();
      this.raf.register(this.block.dataset.instanceIndex, this.render);
    }
  };
}
