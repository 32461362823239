import "@styles/font.css";
import "@styles/common.css";
import "@styles/typography.css";
import "@styles/base.css";
import "@styles/colors.css";
import "@styles/variables.css";
import "@styles/transitions.css";

import Footer from "@blocks/Footer";
import Header from "@blocks/Header";
import Loader from "@blocks/Loader";

import Anchor from "@components/Anchor";
import AnimatedText from "@components/AnimatedText";
import Columns from "@components/Columns";
import TextWithDynamicFontSize from "@components/TextWithDynamicFontSize";
import Button from "@blocks/Button";
import ChiSono from "@blocks/ChiSono";
import Intro from "@blocks/Intro";
import Showreel from "@blocks/Showreel";
import Contattami from "@blocks/Contattami";
import Projects from "@blocks/Projects";
import Viridian from "@blocks/Viridian";
import Tanto from "@blocks/Tanto";
import Checkup from "@blocks/Checkup";
import Coaching from "@blocks/Coaching";
import CTACard from "@blocks/CTACard";
import "@blocks/Link";

import Observer from "@utils/Observer";
import Raf from "@utils/Raf";
import Scroll from "@utils/Scroll";
import vh from "@utils/vh";

import Router from "@router/index.js";

const MODULES = [
  Anchor,
  AnimatedText,
  Columns,
  Footer,
  Header,
  ChiSono,
  Showreel,
  Contattami,
  Projects,
  Viridian,
  Checkup,
  Coaching,
  CTACard,
];

class App {
  lastIndex = 0;

  constructor() {
    window.addEventListener("DOMContentLoaded", this.initApp);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);

    // initialise scroll position
    window.scrollX = 0;
    window.scrollY = 0;
  }

  initApp = async () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js").then(
        function (registration) {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        function (err) {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    }

    this.router = new Router();
    this.loader = new Loader(document.querySelector(".loader"));
    this.observer = new Observer();
    this.raf = new Raf();
    this.scroll = new Scroll();
    this.lastIndex = 0;
    this.instances = [];

    await this.loader.loadFontFaces();

    document.documentElement.style.setProperty("--vh", `${vh()}px`);

    this.scroll.initScroll();

    await this.initItems();
    await this.loader.load();

    console.log("%c Load complete ", "background: black; color: white");

    await this.initClones();

    this.scroll.onReady();

    await this.loader.hide();

    //document.body.classList.add("loaded");

    // Call onLoaderHidden method if any
    await Promise.all(
      this.instances.map(async (instance) => {
        if (instance.onLoaderHidden) {
          await instance.onLoaderHidden();
        }
      })
    );
  };

  initItems = () => {
    return new Promise(async (resolve, reject) => {
      // Create module instances
      const newInstances = MODULES.filter((module) =>
        module.hasOwnProperty("selector")
      ).flatMap((Module) => {
        const blocks = [...document.querySelectorAll(Module.selector)];
        return blocks
          .filter((block) => !block.dataset["instanceIndex" + Module.name])
          .map((block) => {
            const previousIndexes = block.dataset.instanceIndex;
            block.dataset.instanceIndex = previousIndexes
              ? [previousIndexes, this.lastIndex].join(",")
              : this.lastIndex;
            block.dataset["instanceIndex" + Module.name] = this.lastIndex;

            this.lastIndex++;

            return new Module(block);
          });
      });

      // Call onReady method if any
      await Promise.all(
        newInstances.map(async (instance) => {
          if (instance.onReady) {
            await instance.onReady();
          }
        })
      );

      this.instances = this.instances.concat(newInstances);

      resolve(newInstances);
    });
  };

  initClones = async () => {
    return new Promise(async (resolve, reject) => {
      this.instances = this.instances.concat(
        MODULES.flatMap((module) => {
          if (!module.hasOwnProperty("selector")) {
            return;
          }

          const blocks = [...document.querySelectorAll(module.selector)];
          return blocks
            .map((block) => {
              const blockInstanceIndex = block.getAttribute(
                "data-instance-index"
              );
              if (blockInstanceIndex && blockInstanceIndex !== "") {
                return null;
              }

              const previousIndexes = block.dataset.instanceIndex;
              block.dataset.instanceIndex = previousIndexes
                ? [previousIndexes, this.lastIndex].join(",")
                : this.lastIndex;
              block.dataset["instanceIndex" + module.name] = this.lastIndex;

              this.lastIndex++;

              return new module(block);
            })
            .filter((v) => !!v);
        })
      );

      await Promise.all(
        this.instances.map(async (instance) => {
          if (instance.onReady && !instance.mounted) {
            await instance.onReady();
          }
        })
      );

      await Promise.all(
        this.instances.map(async (instance) => {
          if (instance.onComplete && !instance.completed) {
            await instance.onComplete();
          }
        })
      );

      resolve();
    });
  };

  onResize = (e) => {
    if (!this.instances) {
      return;
    }

    document.documentElement.style.setProperty("--vh", `${vh()}px`);

    this.instances
      .filter((i) => !!i)
      .forEach((instance) => {
        if (instance.onResize) {
          instance.onResize(e);
        }
      });

    this.scroll?.onResize();
  };

  getOrInitScroll = () => {
    if (document.querySelector("[data-scroll-container]") && !this.scroll) {
      this.scroll = new Scroll();
    }

    return this.scroll;
  };

  onPageChangeComplete = () => {
    return new Promise(async (resolve, reject) => {
      await Promise.all(
        this.instances.map(async (instance) => {
          if (instance && instance.onPageChangeComplete) {
            await instance.onPageChangeComplete();
          }
        })
      );

      resolve();
    });
  };
}

window.App = new App();

export const getRaf = () => {
  return window.App.raf;
};

export const getObserver = () => {
  return window.App.observer;
};

export const getInstance = (el) => {
  const instanceIndex = el.getAttribute("data-instance-index");

  if (!instanceIndex) {
    return null;
  }

  return window.App.instances[parseInt(instanceIndex, 10)];
};

export const getScroll = () => {
  return window.App.getOrInitScroll();
};
