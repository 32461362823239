import "./style.css";
import CustomImage from "@blocks/Image";
import Carousel from "@components/Carousel";

const IMAGES = [
  { src: "https://i.imgur.com/DfVKkjU.jpg", alt: "" },
  { src: "https://i.imgur.com/0qrLNqL.jpg", alt: "" },
  { src: "https://i.imgur.com/cHcJpK9.jpg", alt: "" },
  { src: "https://i.imgur.com/KMICFsT.jpg", alt: "" },
  { src: "https://i.imgur.com/ks424AT.jpg", alt: "" },
  { src: "https://i.imgur.com/0IdLAEd.jpg", alt: "" },
];

export default class ChiSono {
  static selector = ".chi-sono";

  constructor(block) {
    this.block = block;
    this.imagesNode = block.querySelector(
      ".chi-sono__carousel__wrapper__images"
    );
    this.imageTemplate = block.querySelector(
      "#chi-sono-carousel-image-template"
    );
    this.carouselNode = block.querySelector(
      ".chi-sono__carousel__wrapper.carousel"
    );
    this.arrowNodes = block.querySelectorAll(
      ".chi-sono__carousel__arrows__arrow"
    );
  }

  setupItems = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        IMAGES.map(async (image) => {
          const imageNode =
            this.imageTemplate.content.firstElementChild.cloneNode(true);
          this.imagesNode.appendChild(imageNode);
          await new CustomImage(imageNode, image).onReady();
        })
      );

      resolve();
    });
  };

  setupArrowNodes = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        [...this.arrowNodes].map(async (node) => {
          await node.addEventListener("click", this.onArrowNodeClicked);
        })
      );

      resolve();
    });
  };

  onArrowNodeClicked = (e) => {
    const target = e.currentTarget;
    const direction = target.dataset.carouselArrowDirection;

    switch (direction) {
      case "left":
        this.carousel.decreaseIndex();
        break;

      case "right":
        this.carousel.increaseIndex();
        break;

      default:
        break;
    }
  };

  onCarouselIndexChange = (index) => {
    this.arrowNodes.forEach((node) => {
      node.classList.toggle(
        "chi-sono__carousel__arrows__arrow--disabled",
        (node.dataset.carouselArrowDirection === "left" && index === 0) ||
          (node.dataset.carouselArrowDirection === "right" &&
            index === IMAGES.length - 1)
      );
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.setupItems();
      await this.setupArrowNodes();
      this.carousel = new Carousel(this.carouselNode);
      this.carousel.registerOnIndexChange(this.onCarouselIndexChange);
      this.carousel.onReady();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();
  };

  onResize = () => {
    this.carousel.onResize();
  };
}
