import "./style.css";
import Marquee from "@blocks/Marquee";

export default class Contattami {
  static selector = ".contattami";

  constructor(block) {
    this.block = block;
    this.marqueeNode = block.querySelector(".marquee");
    this.marquee = new Marquee(this.marqueeNode);
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.marquee.onReady();

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();
  };

  onResize = () => {};
}
