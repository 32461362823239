import "./style.css";
//import CustomImage from "@blocks/Image";

export default class Checkup {
  static selector = ".checkup";

  constructor(block) {
    this.block = block;
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {};

  onResize = () => {};
}
