import "./style.css";
import CustomVideo from "@blocks/Video";

export default class Showreel {
  static selector = ".showreel";

  constructor(block) {
    this.block = block;
    this.videoNodes = block.querySelectorAll(".showreel__video");
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await Promise.all(
        [...this.videoNodes].map(async (node) => {
          await new CustomVideo(node).onReady();
        })
      );

      resolve();
    });
  };

  onComplete = () => {
    this.onResize();
  };

  onResize = () => {};
}
