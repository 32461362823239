import Lenis from "@studio-freight/lenis";
import { getRaf } from "@app";

export default class Scroll {
  constructor() {
    this.positionChangeCallbacks = [];
    this.x = window.scrollX;
    this.y = window.scrollY;
  }

  initScroll = () => {
    this.raf?.unregister("scroll");

    if (this.lenis) {
      this.lenis.destroy();
    }

    this.lenis = new Lenis({
      orientation: this.orientation,
    });

    this.raf?.register("scroll", this.render);

    this.lenis.on("scroll", this.onScroll);
    this.scrollTo(this.y, { immediate: true });
  };

  registerOnScrollPositionChange = (callback) => {
    this.positionChangeCallbacks.push(callback);
  };

  scrollTo = (target, options = {}) => {
    this.lenis.scrollTo(target, options);
  };

  render = (timestamp) => {
    this.lenis.raf(timestamp);
  };

  stop = () => {
    this.lenis.stop();
  };

  start = () => {
    this.lenis.start();
  };

  onScroll = (event) => {
    const {
      velocity,
      animatedScroll,
      direction,
      dimensions: { scrollWidth, width, height, scrollHeight },
    } = event;
    const progress = {
      x: animatedScroll / (scrollWidth - width),
      y: animatedScroll / (scrollHeight - height),
    };

    //this.y = animatedScroll;

    this.positionChangeCallbacks.forEach((callback) => {
      callback({
        velocity,
        animatedScroll,
        progress,
        direction,
      });
    });
  };

  onReady = () => {
    this.raf = getRaf();
    this.orientation = undefined;
    this.onResize();
  };

  onResize = () => {
    this.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const orientation = "vertical";
    if (orientation !== this.orientation) {
      this.orientation = orientation;
      this.initScroll();
    }
  };
}
