import "./style.css";
import CustomImage from "@blocks/Image";

export default class ImagesBrick {
  static selector = ".imagesBrick";

  constructor(block, images) {
    this.block = block;
    this.images = images;
    this.imageTemplate = block.querySelector("#images-brick-image-template");
  }

  setupItems = () => {
    return new Promise(async (resolve) => {
      await Promise.all(
        this.images.map(async (image) => {
          const imageNode =
            this.imageTemplate.content.firstElementChild.cloneNode(true);
          this.block.appendChild(imageNode);
          await new CustomImage(imageNode, image).onReady();
        })
      );

      resolve();
    });
  };

  // Block Lifecycle Methods
  onReady = () => {
    return new Promise(async (resolve, reject) => {
      await this.setupItems();

      resolve();
    });
  };

  onResize = () => {};
  onDestroy = () => {};
}
