import "./style.css";
import { getScroll } from "@app";

export default class Header {
  static selector = ".header";

  constructor(block) {
    this.block = block;
  }

  onScrollPositionChange = (event) => {
    const { direction } = event;
    this.block.classList.toggle("header--hidden", direction == 1);
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      /*this.scroll = getScroll();
      this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);*/

      resolve();
    });
  };

  onResize = () => {};
}
