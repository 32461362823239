import "./style.css";
import { getRaf } from "@app";
import { clamp, map } from "@utils/math";

export default class CustomVideo {
  constructor(block) {
    this.block = block;
    this.videoNode = block.querySelector("video");
    this.videoNode.addEventListener("webkitendfullscreen", (e) => {
      this.handleCloseButtonClick();
    });
    this.videoNode.addEventListener("ended", this.onVideoEnded);
    this.videoNode.addEventListener("click", this.onVideoClicked);

    this.controlNodes = block.querySelectorAll(".video__controls__control");
    this.controlTextNode = block.querySelector(".video__controls__control p");

    this.ppTextNodes = block.querySelectorAll(
      ".video__controls__control--pp p"
    );
    this.ppTextNode = block.querySelector(".video__controls__control--pp");
    this.ppTextNode.addEventListener("click", this.togglePlay);

    this.muTextNodes = block.querySelectorAll(
      ".video__controls__control--mu p"
    );
    this.muTextNode = block.querySelector(".video__controls__control--mu");
    this.muTextNode.addEventListener("click", this.toggleMute);

    this.progressCurrentNode = block.querySelector(
      ".video__controls__progress-bar__current"
    );

    this.progressClickableNode = block.querySelector(
      ".video__controls__progress-bar__clickable-area"
    );
    this.progressClickableNode.addEventListener(
      "click",
      this.onProgressClickableNodeClicked
    );
  }

  updateControlNodesSizes = () => {
    this.controlNodes.forEach((node) => {
      node.style.height = `${this.controlTextNode.bounds.height}px`;
    });
  };

  play = () => {
    this.videoNode.play();
    this.block.classList.add("video--play");
    this.ppTextNodes.forEach((node) => {
      node.style.transform = `translateY(0%)`;
    });
  };

  pause = () => {
    this.videoNode.pause();
    this.block.classList.remove("video--play");
    this.ppTextNodes.forEach((node) => {
      node.style.transform = `translateY(-100%)`;
    });
  };

  togglePlay = () => {
    if (this.videoNode.paused) {
      this.play();
    } else {
      this.pause();
    }
  };

  toggleMute = () => {
    this.videoNode.muted = !this.videoNode.muted;
    this.muTextNodes.forEach((node) => {
      node.style.transform = `translateY(${this.videoNode.muted ? -100 : 0}%)`;
    });
  };

  onAnimationFrame = () => {
    this.progressCurrentNode.style.transform = `scaleX(${
      this.videoNode.currentTime / this.videoNode.duration
    })`;
  };

  onVideoEnded = () => {
    this.pause();
  };

  onVideoClicked = () => {
    this.togglePlay();
  };

  onProgressClickableNodeClicked = (event) => {
    const {
      offsetX,
      target: { offsetWidth },
    } = event;

    const newVideoTime = clamp(
      map(offsetX, 0, offsetWidth, 0, this.videoNode.duration),
      0,
      this.videoNode.duration
    );

    this.videoNode.currentTime = newVideoTime;
  };

  // Block Lifecycle Methods
  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      this.pause();

      this.raf = getRaf();
      this.raf.register(this.block.id, this.onAnimationFrame);

      resolve();
    });
  };

  onResize = () => {
    this.controlTextNode.bounds = this.controlTextNode.getBoundingClientRect();
    this.updateControlNodesSizes();
  };
}
