import "./style.css";
import CustomImage from "@blocks/Image";

export default class CTACard {
  static selector = ".cta-card";

  constructor(block) {
    this.block = block;
    this.imageNode = block.querySelector(".cta-card__image");
    this.imageSRC = this.imageNode.dataset.src;
    this.imageALT = this.imageNode.dataset.alt;
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await new CustomImage(this.imageNode, {
        src: this.imageSRC,
        alt: this.imageALT,
      }).onReady();

      resolve();
    });
  };

  onComplete = () => {};

  onResize = () => {};
}
